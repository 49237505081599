<template>
    <section>
        <InsideHeader :title="pageData.page_heading" :bredcrumb="breadCrumb" />  
        <div class="section margin-top_50 fofum_wrap">
            <div class="container">
                <div class="row">
                    <div class="col-sm-9 forum_left">
                        <div class="forumbox" v-if="forumData.length > 0">
                            <div class="forabg" v-for="(item, indx) of forumData" :key="indx">
                                <div class="inner" v-if="(item.hasOwnProperty('topics') && item.topics.length > 0)">
                                    <ul class="topiclist">
                                        <li class="header">
                                            <dl class="icon">
                                                <dt class="header-name"><a href="#">{{ item.category }}</a></dt>
                                                <!-- <dd class="topics">Topics</dd> -->
                                                <dd class="posts">{{ pageContent('total_comments') }}</dd>
                                                <dd class="lastpost"><span>{{ pageContent('last_comment') }}</span></dd>
                                            </dl>
                                        </li>
                                    </ul>
                                    <ul class="topiclist forums">
                                        <li class="row" id="forum2" v-for="(topic, i) of item.topics" :key="i">
                                            <dl class="icon" style="background-image: url(/images/forum_read.svg); background-repeat: no-repeat;">
                                                <dt title="No unread posts">
                                                    <router-link :to="`/forum/${topic.id}/details/`" class="forumtitle">{{ topic.title }}</router-link><br>
                                                    <div class="forum-description" v-if="topic.content" v-html="topic.content"> </div>
                                                </dt>
                                                <!-- <dd class="topics">4 <dfn>Topics</dfn></dd> -->
                                                <dd data-title="Total Comments" class="posts"> {{ topic.total_comments }} </dd>
                                                <dd data-title="Last Comment" class="lastpost" v-if="topic.hasOwnProperty('last_comment')"><span>
                                                    <dfn>Last post</dfn> by {{ topic.last_comment.user }}
                                                    <a href="#"><i class="fa fa-arrow-right grey"></i></a> <br>4 months ago</span>
                                                </dd>
                                                <dd data-title="Last Comment" class="lastpost" v-else>
                                                    {{ pageContent('no_comments') }}
                                                </dd>
                                            </dl>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <NoRecord v-else />
                    </div>
                    <Category :categoryid="0"/>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Category from './Category'
import InsideHeader from "@/components/InsideHeader.vue";
import NoRecord from "@/components/NoRecord.vue";
import Translation from '../../../public/translation.json'

export default {
    components: {Category, InsideHeader, NoRecord},
    data(){
        return {
            breadcrumb: [],
            forumData: [],
            defaultLang: localStorage.getItem('_store_lang') || 'en',
            pageData: []
        }
    },
    computed: {
        breadCrumb(){
            return this.breadcrumb = [
                {title: this.pageContent('home'), path:'/'},
                {title: this.pageContent('forum'), path:'#'}
            ]
        }
    },
    methods: {
        pageContent(field){
            return Translation.content[this.defaultLang][field]
        },
        getPageData() {
            let slug = 'forum'
            this.$store.dispatch('getPageData', slug).then(res=>{
               if(res.data.status === true) {
                    this.pageData = res.data.data
                }
            })
            .catch(err => console.log(err))
        },
        getTopics() {
            let params = {}
            if(this.$route.params.categoryid) 
                params.category_id = this.$route.params.categoryid

            this.$http.get(`forum/topic`, {params}).then(res=> {
                if(res.data.status === true) {
                    this.forumData = res.data.data
                }
            })
            .catch(err => console.log(err))
        }
    },
    created: function(){
        this.getTopics()
        this.getPageData()
    },
    watch: {
        "$route.params.categoryid"(){
            this.getTopics()
        },
    }
}
</script>