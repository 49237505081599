<template>
    <div class="no-record">
        <i class="uil uil-exclamation-triangle"></i>
        <p>{{ noRecord[0].title }}</p>
    </div>
</template>

<script>
export default {
    data(){
        return {
            heading: [
                {lang: 'en', title: `No record found!`},
                {lang: 'ar', title: `!لا يوجد سجلات`},
                {lang: 'fr', title: `Aucun Enregistrement Trouvé!`}
            ],
            defaultLang: localStorage.getItem('_store_lang') || 'en'
        }
    },
    computed: {
        noRecord(){
            return this.heading.filter(item=> {
                return (item.lang === this.defaultLang) ? item : ''
            })
        }
    },
}
</script>